<template>
	<div class="h-100">
		<UserForm @cancel="goToList" @submit="createUser" />
	</div>
</template>

<script>
import { CREATE_USER } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("users")

export default {
	name: "CreateUser",
	components: {
		UserForm: () => import("../components/UserForm"),
	},

	methods: {
		...mapActions({ CREATE_USER }),
		createUser: async function (params) {
			await this.CREATE_USER(params)
		},
		goToList() {
			this.$router.push({ name: "UserManagement" })
		},
	},
}
</script>
